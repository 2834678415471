<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Programación Regalías</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Hidrocarburos</li>
                  <li class="breadcrumb-item active">Programación Regalías</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card" style="display: inherit">
              <div class="card-header pt-2 pb-2">
                <div class="btn-group shadow float-right">
                  <!-- <button
                    type="button"
                    class="btn bg-gradient-success btn-md"
                    data-toggle="modal"
                    data-target="#modal_informe_excel"
                    v-if="
                      $store.getters.can(
                        'hidrocarburos.almacenBonos.exportExcelBonos'
                      )
                    "
                    @click="$refs.BonoExportExcel.limpiarModal()"
                  >
                    <i class="far fa-file-excel"></i>
                  </button> -->
                  <button
                    type="button"
                    class="btn bg-gradient-primary btn-md"
                    style="cursor: pointer"
                    @click="create()"
                  >
                    <i class="fas fa-plus"></i>
                    <!-- <i class="fas fa-user-plus"></i> -->
                  </button>
                  <button
                    type="button"
                    class="btn bg-gradient-info"
                    @click="getIndex()"
                  >
                    <i class="fas fa-sync-alt"></i>
                  </button>
                  <button
                    type="button"
                    class="btn bg-gradient-danger"
                    @click="limpiar()"
                  >
                    <i class="fas fa-broom"></i>
                  </button>
                </div>
              </div>
              <div class="card-body table-responsive p-0">
                <div class="row">
                  <div class="col-md-12">
                    <table
                      class="table table-bordered table-striped table-hover table-sm shadow-sm"
                      style="font-size: 0.8em"
                    >
                      <thead>
                        <tr>
                          <th class="text-center">
                            <label>#</label>
                            <input
                              type="number"
                              v-model="filtros.id"
                              label="id"
                              class="form-control form-control-sm shadow-sm"
                              @keyup.enter="getIndex()"
                            />
                          </th>
                          <th class="text-center">
                            <label>Nombre</label>
                            <input
                              type="text"
                              v-model="filtros.nombre"
                              label="id"
                              class="form-control form-control-sm shadow-sm"
                              @keyup.enter="getIndex()"
                            />
                          </th>
                          <th class="text-center">
                            <label>Nominación</label>
                            <input
                              type="text"
                              v-model="filtros.nominacion"
                              label="id"
                              class="form-control form-control-sm shadow-sm"
                              @keyup.enter="getIndex()"
                            />
                          </th>
                          <th class="text-center">
                            <label>Fecha</label>
                            <input
                              type="date"
                              v-model="filtros.fecha_vigencia"
                              label="id"
                              class="form-control form-control-sm p-0 shadow-sm"
                              @change="getIndex()"
                            />
                          </th>
                          <th class="text-center">
                            Estado
                            <select
                              class="form-control form-control-sm"
                              v-model="filtros.estado"
                              @change="getIndex()"
                            >
                              <option value="">Todos...</option>
                              <option
                                v-for="estado in listasForms.estados"
                                :key="estado.numeracion"
                                :value="estado.numeracion"
                              >
                                {{ estado.descripcion }}
                              </option>
                            </select>
                          </th>
                          <th class="text-center">Acciones</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in programaciones.data" :key="item.id">
                          <td class="text-center">{{ item.id }}</td>
                          <td>{{ item.nombre }}</td>
                          <td>
                            {{ item.nominacion ? item.nominacion.nombre : "" }}
                          </td>
                          <td>{{ item.fecha_vigencia }}</td>
                          <td class="text-center">
                            <span
                              class="badge  shadow"
                              :class="
                                item.estado == 1
                                  ? 'badge badge-pill badge-warning'
                                  : item.estado == 2
                                  ? 'badge badge-pill badge-success'
                                  : ''
                              "
                            >
                              {{ item.nEstado }}
                            </span>
                          </td>
                          <td style="width: 50px">
                            <div class="btn-group float-right shadow-sm">
                              <button
                                type="button"
                                class="btn btn-sm bg-gradient-navy"
                                @click="edit(item)"
                              >
                                <i class="fas fa-edit"></i>
                              </button>
                              <button
                                type="button"
                                class="btn btn-sm bg-gradient-danger"
                              >
                                <i class="fas fa-trash-alt"></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div
                  class="float-left"
                  v-if="programaciones.data && programaciones.data.length > 0"
                >
                  <p>
                    Mostrando del <b>{{ programaciones.from }}</b> al
                    <b>{{ programaciones.to }}</b> de un total:
                    <b>{{ programaciones.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  class="float-right shadow"
                  :data="programaciones"
                  @pagination-change-page="getIndex"
                  :limit="5"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import pagination from "laravel-vue-pagination";
import { mapActions, mapState } from "vuex";
/* import vSelect from "vue-select"; */
import axios from "axios";
import Loading from "../../../../components/Loading";
export default {
  name: "ProgramacionRegaliasIndex",
  components: {
    Loading,
    pagination,
    /* vSelect, */
  },
  data() {
    return {
      page: 1,
      cargando: false,
      bonos: {},
      filtros: {},
      programaciones: {},
      listasForms: {
        estados: [],
      },
      modal: {
        title: "",
        accion: "",
      },
    };
  },

  methods: {
    getIndex(page = 1) {
      this.page = page;
      this.cargando = true;
      axios
        .get("/api/hidrocarburos/cargueProgramaciones/maestro?page=" + page, {
          params: this.filtros,
        })
        .then(async (response) => {
          this.cargando = false;
          this.programaciones = response.data;
        });
    },

    limpiar() {
      this.filtros.nombre = null;
      this.filtros.fecha_vigencia = null;
      this.nominacion_id = null;
      this.filtros.estado = null;
      this.filtros.id = null;
      this.getIndex();
    },

    create() {
      return this.$router.push({
        name: "/Hidrocarburos/ProgramacionesRegaliasForm",
        params: { accion: "Crear", id: 0 },
      });
    },

    edit(programacion) {
      return this.$router.push({
        name: "/Hidrocarburos/ProgramacionesRegaliasForm",
        params: {
          accion: "Editar",
          data_edit: programacion,
          id: programacion.id,
        },
      });
    },

    ...mapActions("modOperacion", ["actGetListas"]),
  },

  computed: {
    ...mapState("modOperacion", ["listas"]),
  },

  beforeMount() {
    this.actGetListas(28).then(() => {
      this.listasForms.estados = this.listas;
    });
  },

  mounted() {
    this.getIndex();
  },
};
</script>
